<template>
    <div class="staff-card">
        <div class="picture">
            <img :src="require('../assets/' + staff.picture)" :alt="staff.name">
            <i v-if="cv" class="bi-plus-circle"><router-link :to="{ name: 'StaffDetails', params: { dentist: staff.name }}">Show CV</router-link></i>
        </div>
        <div class="info">
            <h2>{{ staff.name }}</h2>
            <p>{{ staff.title }}</p>
            <p>GDC Number: {{staff.gdc}}</p>
            <a style="color: var(--foreground);" :href="'mailto:' + staff.email">{{ staff.email }}</a>
            <i v-if="cv" class="bi-plus-circle"><router-link :to="{ name: 'StaffDetails', params: { dentist: staff.name }}">Show CV</router-link></i>
        </div>        
    </div>
</template>

<style scoped>
.info{
    display: flex;
    flex-direction: column;
    margin: 0;
}
.info > p{
    font-size: 1.2rem;
    color: var(--foreground);
}
.info > h2{
    margin: 0;
}
.info > i > a {
    padding-left: .5rem !important;
}
.info > a, .info > i > a{
    font-size: 1.2rem;
    color: var(--primary);
    font-style: normal;
    padding: .5rem 0;
}
.staff-card{
    display: flex;
    flex-wrap: wrap;
    border-radius: 0rem;
    background-color: var(--white);
    box-shadow: 0px 10px 20px -5px var(--dark-gray);
    min-width: 15rem;
}
.picture{
    position: relative;
    width:100%;
    aspect-ratio: 1/1;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0rem;
}
.picture > i {
    position: absolute;
    padding: 0.5rem;
    bottom: .5rem;
    right: 0;
    background-color: var(--foreground);
    color: var(--background);
    opacity: .80;
}
.picture > i > a{
    padding-left: .5rem;
    font-style: normal;
    color: var(--background);
    text-decoration: none;
}
.picture>img{
    width:100%;
    height:100%;
    border-radius: 0rem;
    object-fit: cover;
}
p{
    margin: 0;
}
@media screen and (min-width: 768px){
}
@media screen and (min-width: 1024px){
    .staff-card{
        width: 20%;
    }
    .picture{
    width:100%;
    margin-left: 0;
    margin-right: 0;
    }
}
</style>
<script>
export default {
    data(){
       return{
       }
   },
   props:{
       staff: Object,
       cv: Boolean
   }
}
</script>