<template>
    <div class="staff">
        <intro title="Our Staff" :content="paragraphs" id="intro"/>
        <div class="pgcontent">
            <div>
                <h2>Our Dentists</h2>     
                <div class="cards">
                    <staff-card v-for="staff in Dentists['Dentists']" :key="staff.name" :staff="staff" :cv="true"></staff-card>
                </div>       
                
                <h2>Our Hygenists</h2>
                <div class="cards">
                    <staff-card v-for="staff in Hygenists['Hygenists']" :key="staff.name" :staff="staff" :cv="false"></staff-card>
                </div>
                <h2>Our Staff</h2>
                <div class="cards">
                    <staff-card v-for="staff in Staff['Staff']" :key="staff.name" :staff="staff" :cv="false"></staff-card>
                </div>
            </div>
            <div class="side">
                <!-- <img :src="require('../assets/BROADSTREET-113.jpg')" :alt="side-banner"> -->
            </div>
        </div>
        
    </div>
</template>

<script>
import Dentists from "../assets/json/dentists.json";
import Hygenists from "../assets/json/hygenists.json";
import Staff from "../assets/json/staff.json";
import Intro from '../components/Intro.vue';
import StaffCard from '../components/StaffCard.vue';
export default {
    name: 'Staff',
    components: {
        Intro,
        StaffCard    
    },
    data(){
        return{
            paragraphs: [
                "At Broad Street Dental Practice we have a great team who provide the very best care for our patients.",
                "If you have any questions about your treatment, please don't hesitate to ask any member of our staff who will be only too pleased to help."
            ],
            Staff,
            Dentists,
            Hygenists
        }
    }
}
</script>

<style scoped>
.pgcontent{
    background-color: var(--primary-variant);
    padding-top: 2rem;
    position: relative;
}
.pgcontent > div{
    padding: 0 2rem;
}
.cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}
.pgcontent > * > h2{
    color: var(--font-dark);
}
.content{
    color: var(--font-dark);
}
.sub-header{
    width: 100%;
}
.side{
    display: none;
}
span{
    padding: 1rem 0;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
}
.flex{
    display: flex;
    
}


@media screen and (min-width: 768px){
    .pgcontent{
        display: flex;
        padding: 1rem 2rem;
    }
    .cards{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (min-width: 1024px){
    .cards{
        display: flex;
        flex-wrap: wrap;
    }
    .side{
        display: flex;
        width: 17rem;
        aspect-ratio: 1/2;
        position: absolute;
        top: 5.6rem;
        right: 3rem;
        object-fit: cover;
        background-color: gray;
        background-image: url('../assets/BROADSTREET-113.jpg');
        background-size: cover;
    }
}
@media screen and (min-width: 1440px) {
    .pgcontent{
        padding: 1rem 0 0 8rem;        
    }
    .side{
        width: 25rem;
        right: 8rem;
    }
    
} 

</style>